import type { MedusaProduct } from "src/types";
import { activeProduct, updatePrice } from "./model";
import { useComputed } from "@preact/signals";
import { useSignalEffect } from "@preact/signals";
import type { GetImageResult } from "astro";

interface Props {
  product: MedusaProduct;
  images: Record<string, { images: GetImageResult[]; preview: GetImageResult }>;
}

export const CardDesign = ({ product, images }: Props) => {
  const handleCardChanged = () => {
    activeProduct.value = product;
  };

  const checked = useComputed(() => activeProduct.value?.id === product.id);

  useSignalEffect(updatePrice);

  return (
    <>
      <input
        class="selector__card-input"
        type="radio"
        name="card-design"
        value={product.id}
        aria-label={`${product.title} card design`}
        onClick={handleCardChanged}
        id={product.id}
        checked={checked}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label for={product.id} class="selector__card-label">
        <img
          src={images?.[product.id]?.preview.src}
          height={44}
          width={78}
          alt={`${product.title} card design`}
        />
      </label>
    </>
  );
};
