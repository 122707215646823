import "./CardDesignSelector.scss";
import { useEffect, useRef } from "preact/compat";
import { CardDesign } from "../CardDesign";
import { register, type SwiperContainer } from "swiper/element/bundle";
import { activeProduct, cardsBySet } from "../model";
import classNames from "classnames";
import { useComputed } from "@preact/signals";
import type { GetImageResult } from "astro";

const swiperParams = {
  injectStyles: [
    `
    .swiper-button-next, .swiper-button-prev {
        --swiper-navigation-top-offset: 37px;
        color: white;
        width: 28px;
        height: 24px;
        border-radius: 12px;
        background-color: #1F1F1F;
        svg {
        width: 6px;
        }
    }
    .swiper-button-disabled {
        visibility: hidden;
    }
  `,
  ],
  slidesPerView: "auto",
  navigation: true,
  cssMode: true,
  breakpoints: {
    768: {
      cssMode: false,
    },
  },
  centeredSlides: false,
  speed: 800,
  watchSlidesProgress: true,
};

interface Props {
  labelText: string;
  images: Record<string, { images: GetImageResult[]; preview: GetImageResult }>;
}

const SliderSkeleton = ({ skeletonsAmount = 1 }) => {
  return (
    <div className="skeleton__wrapper">
      {[...Array(skeletonsAmount)].map(() => (
        <div className="skeleton__item"></div>
      ))}
    </div>
  );
};

export const CardDesignSelector = ({ labelText, images }: Props) => {
  const swiperRef = useRef<SwiperContainer | null>(null);

  const isLoading = cardsBySet.value.length === 0;

  useEffect(() => {
    if (isLoading) return;
    register();
    Object.assign(swiperRef.current!, swiperParams);
    swiperRef.current!.initialize();
  }, [cardsBySet.value]);

  const cardDesignName = useComputed(() => activeProduct.value?.subtitle ?? "");

  return (
    <div class="сard-design">
      <span class="card-design__label">
        {labelText}
        <span class="card-design__name">{cardDesignName}</span>
      </span>
      <swiper-container
        init="false"
        class="card-design__wrapper"
        id="card-design"
        ref={swiperRef}
      >
        {isLoading ? <SliderSkeleton /> : null}
        {cardsBySet.value.map((product, index) => (
          <swiper-slide
            key={product.id}
            class={classNames("card-design__item", {
              "swiper-slide-visible":
                index === 0 || index === cardsBySet.value.length - 1,
            })}
          >
            <CardDesign images={images} product={product} />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
};
